import { ref, watch } from "@vue/composition-api";
import axios from "@axios";
// import store from '@/store'

export default function useTicketsHandler(props, emit) {
    // ------------------------------------------------
    // ticketLocal
    // ------------------------------------------------
    const ticketLocal = ref(JSON.parse(JSON.stringify(props.ticket.value)));
    const resetTicketLocal = () => {
        ticketLocal.value = JSON.parse(JSON.stringify(props.ticket.value));
    };
    watch(props.ticket, () => {
        resetTicketLocal();
    });

    // ------------------------------------------------
    // isEventHandlerSidebarActive
    // * Clear form if sidebar is closed
    // ! We can hide it using @hidden event
    // ------------------------------------------------
    // watch(props.isEventHandlerSidebarActive, val => {
    //   // ? Don't reset event till transition is finished
    //   if (!val) {
    //     setTimeout(() => {
    //       clearForm.value()
    //     }, 350)
    //   }
    // })

    const onSubmit = () => {
        const ticketData = JSON.parse(JSON.stringify(ticketLocal));

        // * If event has id => Edit Event
        // Emit event for add/update event
        if (props.ticket.value.id) emit("update-ticket", ticketData.value);
        else emit("add-ticket", ticketData.value);

        // Close sidebar
        emit("update:is-ticket-handler-sidebar-active", false);
    };

    // *===============================================---*
    // *--------- UI ---------------------------------------*
    // *===============================================---*

    // ------------------------------------------------
    // guestOptions
    // ------------------------------------------------

    /* eslint-disable global-require */
    // const assigneeOptions = [
    //   { avatar: require('@/assets/images/avatars/1-small.png'), fullName: 'Jane Foster' },
    //   { avatar: require('@/assets/images/avatars/3-small.png'), fullName: 'Donna Frank' },
    //   { avatar: require('@/assets/images/avatars/5-small.png'), fullName: 'Gabrielle Robertson' },
    //   { avatar: require('@/assets/images/avatars/7-small.png'), fullName: 'Lori Spears' },
    //   { avatar: require('@/assets/images/avatars/9-small.png'), fullName: 'Sandy Vega' },
    //   { avatar: require('@/assets/images/avatars/11-small.png'), fullName: 'Cheryl May' },
    // ]

    const assigneeOptions = ref([]);
    axios.get("user").then(res => {
        assigneeOptions.value = res.data.users;
    });

    /* eslint-enable global-require */

    const resolveAvatarVariant = tags => {
        if (tags.includes("high")) return "primary";
        if (tags.includes("medium")) return "warning";
        if (tags.includes("low")) return "success";
        if (tags.includes("update")) return "danger";
        if (tags.includes("team")) return "info";
        return "primary";
    };

    const tagOptions = ref([]);
    const sourceOptions = ref([]);

    axios.get("/supportTicket").then(res => {
        tagOptions.value = res.data.supportTicketStatuses;
    });

    axios.get("/supportTicket").then(res => {
        sourceOptions.value = res.data.supportTicketSources;
    });

    return {
        ticketLocal,
        resetTicketLocal,

        // UI
        assigneeOptions,
        resolveAvatarVariant,
        tagOptions,
        sourceOptions,
        onSubmit,
    };
}
