<template>
    <div>
        <b-sidebar
            id="sidebar-task-handler"
            sidebar-class="sidebar-lg"
            :visible="isTicketHandlerSidebarActive"
            bg-variant="white"
            shadow
            backdrop
            no-header
            right
            @change="val => $emit('update:is-ticket-handler-sidebar-active', val)"
            @hidden="clearForm"
        >
            <template #default="{ hide }">
                <!-- Header -->
                <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                    <!-- <b-button
                        v-if="ticketLocal.id"
                        size="sm"
                        :variant="ticketLocal.isCompleted ? 'outline-success' : 'outline-secondary'"
                        @click="ticketLocal.isCompleted = !ticketLocal.isCompleted"
                    >
                        {{ ticketLocal.isCompleted ? "Completed" : "Mark Complete" }}
                    </b-button> -->
                    <h5 v-if="!ticketLocal.id" class="mb-0">Add ticket</h5>
                    <div>
                        <feather-icon
                            v-show="ticketLocal.id"
                            icon="TrashIcon"
                            class="cursor-pointer"
                            @click="
                                $emit('remove-ticket');
                                hide();
                            "
                        />
                        <!-- <feather-icon
                            class="ml-1 cursor-pointer"
                            icon="StarIcon"
                            size="16"
                            :class="{ 'text-warning': ticketLocal.isImportant }"
                            @click="ticketLocal.isImportant = !ticketLocal.isImportant"
                        /> -->
                        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
                    </div>
                </div>

                <!-- Body -->
                <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
                    <!-- Form -->
                    <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
                        <!-- Title -->
                        <validation-provider #default="validationContext" name="Title" rules="required">
                            <b-form-group label="Title" label-for="ticket-subject">
                                <b-form-input
                                    id="ticket-subject"
                                    v-model="ticketLocal.subject"
                                    autofocus
                                    :state="getValidationState(validationContext)"
                                    trim
                                    placeholder="Ticket Subject"
                                />

                                <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>

                        <!-- Assignee -->
                        <b-form-group label="Assignee" label-for="assignee">
                            <v-select
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="assigneeOptions"
                                label="fullName"
                                class="assignee-selector"
                                input-id="assignee"
                                :value="ticketLocal.user"
                                @input="val => updateAssignee(val)"
                            >
                                <template #option="{ fullName, avatar }">
                                    <b-avatar size="26" :src="avatar" variant="light-secondary" />
                                    <span class="ml-50 d-inline-block align-middle"> {{ fullName }}</span>
                                </template>

                                <template #selected-option="{ fullName, avatar }">
                                    <b-avatar
                                        size="26"
                                        :src="avatar"
                                        variant="light-primary"
                                        :text="avatarText(fullName)"
                                    />

                                    <span class="ml-50 d-inline-block align-middle"> {{ fullName }}</span>
                                </template>
                            </v-select>
                        </b-form-group>

                        <!-- Orders -->
                        <b-form-group label="Order" label-for="order">
                            <v-select
                                id="order"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                class="assignee-selector"
                                input-id="order"
                                placeholder="Search orders..."
                                label="originId"
                                :options="orderOptions"
                                :value="ticketLocal.order"
                                @search="searchOrders"
                                @input="val => updateOrder(val)"
                            >
                                <template #option="{ originId, total, customer, shopName }">
                                    <p class="m-0 p-0">
                                        <span class="font-weight-bold"
                                            >{{
                                                shopName
                                                    .split(" ")
                                                    .map(n => n[0])
                                                    .join("")
                                            }}
                                            -
                                        </span>
                                        <span class="font-weight-bolder">#{{ originId }}</span>
                                        <span v-if="customer && customer.firstName"> - {{ customer.firstName }}</span>
                                        <span v-if="customer && customer.lastName"> {{ customer.lastName }}</span>
                                        - {{ total | formatCurrency }}
                                    </p>
                                </template>
                            </v-select>
                        </b-form-group>

                        <!-- Status -->
                        <b-form-group label="Status" label-for="status">
                            <v-select
                                :value="ticketLocal.status"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="tagOptions"
                                input-id="status"
                                @input="val => updateStatus(val)"
                            >
                                <template #option="{ label, variant }">
                                    <span class="bullet bullet-sm ml-0" :class="`bullet-${variant}`" />
                                    <span class="ml-1">{{ label }} </span>
                                </template>
                            </v-select>
                        </b-form-group>

                        <!-- Source -->
                        <b-form-group label="Source" label-for="source">
                            <v-select
                                :value="ticketLocal.source"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="sourceOptions"
                                input-id="source"
                                label="name"
                                @input="val => updateSource(val)"
                            >
                                <template #option="{ name, color }">
                                    <span class="bullet bullet-sm ml-0" :class="`bullet-${color}`" />
                                    <span class="ml-1">{{ name }}</span>
                                </template>
                            </v-select>
                        </b-form-group>

                        <!-- Details -->
                        <b-form-group label="Details" label-for="ticket-details">
                            <b-form-textarea
                                id="ticket-details"
                                v-model="ticketLocal.details"
                                placeholder="Details..."
                                rows="3"
                            />
                        </b-form-group>

                        <!-- Form Actions -->
                        <div class="d-flex mt-2">
                            <b-button
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                variant="primary"
                                class="mr-2"
                                type="submit"
                            >
                                {{ ticketLocal.id ? "Update" : "Add " }}
                            </b-button>
                            <b-button
                                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                                type="reset"
                                variant="outline-secondary"
                            >
                                Reset
                            </b-button>
                        </div>
                    </b-form>
                </validation-observer>
            </template>
        </b-sidebar>
    </div>
</template>

<script>
import {
    BSidebar,
    BAvatar,
    BForm,
    BFormGroup,
    BFormTextarea,
    BFormInput,
    BButton,
    BFormInvalidFeedback,
} from "bootstrap-vue";
import vSelect from "vue-select";
// import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, url } from "@validations";
import { avatarText } from "@core/utils/filter";
import formValidation from "@core/comp-functions/forms/form-validation";
import { toRefs, ref } from "@vue/composition-api";
import { debounce } from "debounce";
import useTicketsHandler from "./useTicketsHandler";
import store from "@/store";

export default {
    components: {
        // BSV
        BButton,
        BSidebar,
        BForm,
        BFormGroup,
        BFormTextarea,
        BFormInput,
        BFormInvalidFeedback,
        BAvatar,
        // 3rd party packages
        vSelect,
        // flatPickr,

        // Form Validation
        ValidationProvider,
        ValidationObserver,
    },
    directives: {
        Ripple,
    },
    model: {
        prop: "isTicketHandlerSidebarActive",
        event: "update:is-ticket-handler-sidebar-active",
    },
    props: {
        isTicketHandlerSidebarActive: {
            type: Boolean,
            required: true,
        },
        ticket: {
            type: Object,
            required: true,
        },
        clearTicketData: {
            type: Function,
            required: true,
        },
    },
    data() {
        return {
            required,
            email,
            url,
        };
    },
    setup(props, { emit }) {
        const {
            ticketLocal,
            resetTicketLocal,

            // UI
            assigneeOptions,
            onSubmit,
            tagOptions,
            sourceOptions,
            resolveAvatarVariant,
        } = useTicketsHandler(toRefs(props), emit);

        const { refFormObserver, getValidationState, resetForm, clearForm } = formValidation(
            resetTicketLocal,
            props.clearTicketData,
        );

        const orderOptions = ref([]);

        const getOrdersWQuery = (query, loading) => {
            store
                .dispatch("ecommerce-tickets/fetchOrdersWQuery", { query })
                .then(res => {
                    orderOptions.value = res.data.orders;
                })
                .finally(() => {
                    loading(false);
                });
        };

        const searchOrders = (search, loading) => {
            if (search.length) {
                loading(true);
                debounce(getOrdersWQuery(search, loading), 500);
            }
        };

        const updateOrder = orderObj => {
            if (!orderObj) {
                ticketLocal.value.orderId = null;
            } else if (orderObj.id) {
                ticketLocal.value.orderId = Number(orderObj.id);
            }
        };

        const updateStatus = statusObj => {
            if (!statusObj) {
                ticketLocal.value.statusId = null;
            } else if (!statusObj.id) {
                ticketLocal.value.statusId = null;
            } else if (statusObj.id) {
                ticketLocal.value.statusId = Number(statusObj.id);
            }
        };

        const updateSource = sourceObj => {
            if (!sourceObj) {
                ticketLocal.value.sourceId = null;
            } else if (sourceObj.id) {
                ticketLocal.value.sourceId = Number(sourceObj.id);
            }
        };

        const updateAssignee = assigneeObj => {
            if (!assigneeObj) {
                ticketLocal.value.userId = null;
            } else if (assigneeObj.id) {
                ticketLocal.value.userId = Number(assigneeObj.id);
            }
        };

        return {
            // Add New
            ticketLocal,
            onSubmit,
            assigneeOptions,
            tagOptions,

            // Form Validation
            resetForm,
            clearForm,
            refFormObserver,
            getValidationState,

            // UI
            resolveAvatarVariant,

            // Filter/Formatter
            avatarText,

            // Search Orders
            getOrdersWQuery,
            orderOptions,
            searchOrders,
            updateOrder,
            // Status
            updateStatus,
            updateSource,
            sourceOptions,
            updateAssignee,
        };
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/quill.scss";
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";

.assignee-selector {
    ::v-deep .vs__dropdown-toggle {
        padding-left: 0;
    }
}

//#quil-content ::v-deep {
//    > .ql-container {
//        border-bottom: 0;
//    }
//
//    + #quill-toolbar {
//        border-top-left-radius: 0;
//        border-top-right-radius: 0;
//        border-bottom-left-radius: $border-radius;
//        border-bottom-right-radius: $border-radius;
//    }
// }
</style>
