var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-sidebar',{attrs:{"id":"sidebar-task-handler","sidebar-class":"sidebar-lg","visible":_vm.isTicketHandlerSidebarActive,"bg-variant":"white","shadow":"","backdrop":"","no-header":"","right":""},on:{"change":function (val) { return _vm.$emit('update:is-ticket-handler-sidebar-active', val); },"hidden":_vm.clearForm},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[(!_vm.ticketLocal.id)?_c('h5',{staticClass:"mb-0"},[_vm._v("Add ticket")]):_vm._e(),_c('div',[_c('feather-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.ticketLocal.id),expression:"ticketLocal.id"}],staticClass:"cursor-pointer",attrs:{"icon":"TrashIcon"},on:{"click":function($event){_vm.$emit('remove-ticket');
                            hide();}}}),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1)]),_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var handleSubmit = ref.handleSubmit;
return [_c('b-form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)},"reset":function($event){$event.preventDefault();return _vm.resetForm($event)}}},[_c('validation-provider',{attrs:{"name":"Title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"Title","label-for":"ticket-subject"}},[_c('b-form-input',{attrs:{"id":"ticket-subject","autofocus":"","state":_vm.getValidationState(validationContext),"trim":"","placeholder":"Ticket Subject"},model:{value:(_vm.ticketLocal.subject),callback:function ($$v) {_vm.$set(_vm.ticketLocal, "subject", $$v)},expression:"ticketLocal.subject"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),_c('b-form-group',{attrs:{"label":"Assignee","label-for":"assignee"}},[_c('v-select',{staticClass:"assignee-selector",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.assigneeOptions,"label":"fullName","input-id":"assignee","value":_vm.ticketLocal.user},on:{"input":function (val) { return _vm.updateAssignee(val); }},scopedSlots:_vm._u([{key:"option",fn:function(ref){
                            var fullName = ref.fullName;
                            var avatar = ref.avatar;
return [_c('b-avatar',{attrs:{"size":"26","src":avatar,"variant":"light-secondary"}}),_c('span',{staticClass:"ml-50 d-inline-block align-middle"},[_vm._v(" "+_vm._s(fullName))])]}},{key:"selected-option",fn:function(ref){
                            var fullName = ref.fullName;
                            var avatar = ref.avatar;
return [_c('b-avatar',{attrs:{"size":"26","src":avatar,"variant":"light-primary","text":_vm.avatarText(fullName)}}),_c('span',{staticClass:"ml-50 d-inline-block align-middle"},[_vm._v(" "+_vm._s(fullName))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Order","label-for":"order"}},[_c('v-select',{staticClass:"assignee-selector",attrs:{"id":"order","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"input-id":"order","placeholder":"Search orders...","label":"originId","options":_vm.orderOptions,"value":_vm.ticketLocal.order},on:{"search":_vm.searchOrders,"input":function (val) { return _vm.updateOrder(val); }},scopedSlots:_vm._u([{key:"option",fn:function(ref){
                            var originId = ref.originId;
                            var total = ref.total;
                            var customer = ref.customer;
                            var shopName = ref.shopName;
return [_c('p',{staticClass:"m-0 p-0"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(shopName .split(" ") .map(function (n) { return n[0]; }) .join(""))+" - ")]),_c('span',{staticClass:"font-weight-bolder"},[_vm._v("#"+_vm._s(originId))]),(customer && customer.firstName)?_c('span',[_vm._v(" - "+_vm._s(customer.firstName))]):_vm._e(),(customer && customer.lastName)?_c('span',[_vm._v(" "+_vm._s(customer.lastName))]):_vm._e(),_vm._v(" - "+_vm._s(_vm._f("formatCurrency")(total))+" ")])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Status","label-for":"status"}},[_c('v-select',{attrs:{"value":_vm.ticketLocal.status,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.tagOptions,"input-id":"status"},on:{"input":function (val) { return _vm.updateStatus(val); }},scopedSlots:_vm._u([{key:"option",fn:function(ref){
                            var label = ref.label;
                            var variant = ref.variant;
return [_c('span',{staticClass:"bullet bullet-sm ml-0",class:("bullet-" + variant)}),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(label)+" ")])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Source","label-for":"source"}},[_c('v-select',{attrs:{"value":_vm.ticketLocal.source,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.sourceOptions,"input-id":"source","label":"name"},on:{"input":function (val) { return _vm.updateSource(val); }},scopedSlots:_vm._u([{key:"option",fn:function(ref){
                            var name = ref.name;
                            var color = ref.color;
return [_c('span',{staticClass:"bullet bullet-sm ml-0",class:("bullet-" + color)}),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(name))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Details","label-for":"ticket-details"}},[_c('b-form-textarea',{attrs:{"id":"ticket-details","placeholder":"Details...","rows":"3"},model:{value:(_vm.ticketLocal.details),callback:function ($$v) {_vm.$set(_vm.ticketLocal, "details", $$v)},expression:"ticketLocal.details"}})],1),_c('div',{staticClass:"d-flex mt-2"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2",attrs:{"variant":"primary","type":"submit"}},[_vm._v(" "+_vm._s(_vm.ticketLocal.id ? "Update" : "Add ")+" ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"reset","variant":"outline-secondary"}},[_vm._v(" Reset ")])],1)],1)]}}],null,true)})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }