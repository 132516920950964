import axios from "@axios";

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchTickets(_, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/supportTicket", {
                        params: queryParams,
                    })
                    .then(response => resolve(response.data))
                    .catch(error => reject(error));
            });
        },
        fetchOrdersWQuery(ctx, { query }) {
            return new Promise((resolve, reject) => {
                axios
                    .get(
                        `/order?q=${query}&perPage=25&page=1&sortBy=originCreateDate&sortDesc=true&showOnHold=true&onlyMyOrders=false`,
                    )
                    .then(res => resolve(res))
                    .catch(err => reject(err));
            });
        },
        fetchOrders(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/order", {
                        params: queryParams,
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        addTicket(ctx, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/supportTicket", payload)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => reject(error));
            });
        },
        updateTicket(ctx, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .put(`/supportTicket/${payload.ticket.id}`, payload)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => reject(error));
            });
        },
        removeTicket(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/supportTicket/${id}`)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => reject(error));
            });
        },
    },
};
