<template>
    <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
    <div style="height: inherit">
        <div
            class="body-content-overlay"
            :class="{ show: mqShallShowLeftSidebar }"
            @click="mqShallShowLeftSidebar = false"
        />
        <div class="todo-app-list">
            <!-- App Searchbar Header -->
            <div class="app-fixed-search d-flex align-items-center">
                <!-- Toggler -->
                <div class="sidebar-toggle d-block d-lg-none ml-1">
                    <feather-icon
                        icon="MenuIcon"
                        size="21"
                        class="cursor-pointer"
                        @click="mqShallShowLeftSidebar = true"
                    />
                </div>

                <!-- Searchbar -->
                <div class="d-flex align-content-center justify-content-between w-100">
                    <b-input-group class="input-group-merge">
                        <b-input-group-prepend is-text>
                            <feather-icon icon="SearchIcon" class="text-muted"/>
                        </b-input-group-prepend>
                        <b-form-input :value="searchQuery" placeholder="Search tickets" @input="updateRouteQuery"/>
                    </b-input-group>
                </div>

                <!-- Dropdown -->
                <div class="dropdown">
                    <b-dropdown variant="link" no-caret toggle-class="p-0 mr-1" right>
                        <template #button-content>
                            <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                        </template>
                        <b-dropdown-item @click="resetSortAndNavigate"> Reset Sort </b-dropdown-item>
                        <b-dropdown-item :to="{ name: $route.name, query: { ...$route.query, sort: 'title-asc' } }">
                            Sort A-Z
                        </b-dropdown-item>
                        <b-dropdown-item :to="{ name: $route.name, query: { ...$route.query, sort: 'title-desc' } }">
                            Sort Z-A
                        </b-dropdown-item>
                        <b-dropdown-item :to="{ name: $route.name, query: { ...$route.query, sort: 'assignee' } }">
                            Sort Assignee
                        </b-dropdown-item>
                        <b-dropdown-item :to="{ name: $route.name, query: { ...$route.query, sort: 'due-date' } }">
                            Sort Due Date
                        </b-dropdown-item>
                    </b-dropdown>
                </div>
            </div>

            <!-- Tickets List -->
            <vue-perfect-scrollbar
                :settings="perfectScrollbarSettings"
                class="todo-task-list-wrapper list-group scroll-area"
            >
                <ul class="todo-task-list media-list">
                    <li v-for="ticket in tickets" :key="ticket.id" class="todo-item" @click="handleTicketClick(ticket)">
                        <div class="todo-title-wrapper">
                            <div class="todo-title-area">
                                <div class="title-wrapper">
                                    <b-avatar
                                        v-if="ticket.user"
                                        size="32"
                                        :src="ticket.user.avatar"
                                        variant="light-primary"
                                        :text="avatarText(ticket.user.fullName)"
                                    />
                                    <b-avatar v-else size="32" variant="light-secondary">
                                        <feather-icon icon="UserIcon" size="16"/>
                                    </b-avatar>
                                    <span class="todo-title font-weight-bolder">{{ ticket.subject }}</span>
                                    <small>#{{ ticket.order.originId }}</small>
                                </div>
                            </div>
                            <div class="todo-item-action">
                                <div class="badge-wrapper mr-1">
                                    <b-badge
                                        pill
                                        :variant="`light-${ticket.status.variant}`"
                                        class="text-capitalize"
                                    >
                                        {{ ticket.status.label }}
                                    </b-badge>
                                </div>
                                <small class="text-nowrap text-muted mr-1">{{ ticket.updatedDate | formatDate }}</small>
                                <b-avatar
                                    v-if="ticket.user"
                                    size="32"
                                    :src="ticket.user.avatar"
                                    variant="light-info"
                                    :text="avatarText(ticket.user.fullName)"
                                />
                                <b-avatar v-else size="32" variant="light-warning">
                                    <feather-icon icon="UserIcon" size="16"/>
                                </b-avatar>
                            </div>
                        </div>
                    </li>
                </ul>
              <div class="no-results text-center my-1" :class="{show: ticketsLoading }">
                <b-spinner style="width: 3rem; height: 3rem" variant="primary" />
              </div>
                <div class="no-results" :class="{ show: !tickets.length && !ticketsLoading }">
                    <h5>No Tickets Found</h5>
                </div>
            </vue-perfect-scrollbar>
        </div>

        <!-- ticket Handler -->
        <tickets-handler-sidebar
            v-model="isTicketHandlerSidebarActive"
            :ticket="ticket"
            :clear-ticket-data="clearTicketData"
            @remove-ticket="removeTicket"
            @add-ticket="addTicket"
            @update-ticket="updateTicket"
        />

        <!-- Sidebar -->
        <portal to="content-renderer-sidebar-left">
            <tickets-left-sidebar
                :ticket-tags="ticketTags"
                :is-ticket-handler-sidebar-active.sync="isTicketHandlerSidebarActive"
                :class="{ show: mqShallShowLeftSidebar }"
                @close-left-sidebar="mqShallShowLeftSidebar = false"
            />
        </portal>
    </div>
</template>

<script>
import store from "@/store";
import {ref, watch, computed, onUnmounted} from "@vue/composition-api";
import {BFormInput, BAvatar, BInputGroup, BSpinner, BInputGroupPrepend, BBadge, BDropdown, BDropdownItem} from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import {avatarText} from "@core/utils/filter";
import {useRouter} from "@core/utils/utils";
import {useResponsiveAppLeftSidebarVisibility} from "@core/comp-functions/ui/app";
import TicketsLeftSidebar from "./TicketsLeftSidebar.vue";
import ticketsStoreModule from "./ticketsStoreModule";
import TicketsHandlerSidebar from "./TicketsHandlerSidebar.vue";
import axios from "@axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {useToast} from "vue-toastification/composition";

export default {
    components: {
        BFormInput,
        BInputGroup,
      BSpinner,
        BInputGroupPrepend,
        BBadge,
        BAvatar,
        VuePerfectScrollbar,
        BDropdown,
        BDropdownItem,

        // App SFC
        TicketsLeftSidebar,
        TicketsHandlerSidebar,
    },
    setup() {
        const toast = useToast();

        const TICKETS_APP_STORE_MODULE_NAME = "ecommerce-tickets";

        // Register module
        if (!store.hasModule(TICKETS_APP_STORE_MODULE_NAME))
            store.registerModule(TICKETS_APP_STORE_MODULE_NAME, ticketsStoreModule);

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(TICKETS_APP_STORE_MODULE_NAME)) store.unregisterModule(TICKETS_APP_STORE_MODULE_NAME);
        });

        const {route, router} = useRouter();
        const routeSortBy = computed(() => route.value.query.sort);
        const routeQuery = computed(() => route.value.query.q);
        const routeParams = computed(() => route.value.params);
        watch(routeParams, () => {
            // eslint-disable-next-line no-use-before-define
            fetchTickets();
        });

        const ticketsLoading = ref(true);
        const tickets = ref([]);
        const sortOptions = ["latest", "title-asc", "title-desc", "assignee", "due-date"];
        const sortBy = ref(routeSortBy.value);

        watch(routeSortBy, val => {
            if (sortOptions.includes(val)) sortBy.value = val;
            else sortBy.value = val;
        });

        const resetSortAndNavigate = () => {
            const currentRouteQuery = JSON.parse(JSON.stringify(route.value.query));

            delete currentRouteQuery.sort;

            router.replace({name: route.name, query: currentRouteQuery}).catch(() => {
            });
        };

        const blankTicket = {
            subject: null,
            orderId: null,
            userId: null,
            details: null,
            statusId: null,
        };
        const ticket = ref(JSON.parse(JSON.stringify(blankTicket)));
        const clearTicketData = () => {
            ticket.value = JSON.parse(JSON.stringify(blankTicket));
        };

        const addTicket = val => {
            if (!val) return;
            store.dispatch("ecommerce-tickets/addTicket", val)
              .then(() => {
                    toast({
                        component: ToastificationContent,
                        props: {
                            title: "Ticket Added",
                            icon: "CheckCircleIcon",
                            variant: "success"
                        }
                    })
                })
                .then(() => {
                    // eslint-disable-next-line no-use-before-define
                    fetchTickets();
                })
                .catch(() => {
                    toast({
                        component: ToastificationContent,
                        props: {
                            title: "Ticket Add Failed",
                            icon: "AlertTriangleIcon",
                            variant: "danger"
                        }
                    })
                });
        };
        const removeTicket = () => {
            store.dispatch("ecommerce-tickets/removeTicket", {id: ticket.value.id})
                .then(() => {
                    toast({
                        component: ToastificationContent,
                        props: {
                            title: "Ticket Removed",
                            icon: "CheckCircleIcon",
                            variant: "success"
                        }
                    })
                })
                .then(() => {
                    // eslint-disable-next-line no-use-before-define
                    fetchTickets();
                })
                .catch(() => {
                    toast({
                        component: ToastificationContent,
                        props: {
                            title: "Ticket Remove Failed",
                            icon: "AlertTriangleIcon",
                            variant: "danger"
                        }
                    })
                });
        };
        const updateTicket = ticketData => {
            store.dispatch("ecommerce-tickets/updateTicket", {ticket: ticketData})
                .then(() => {
                    toast({
                        component: ToastificationContent,
                        props: {
                            title: "Ticket Updated",
                            icon: "CheckCircleIcon",
                            variant: "success"
                        }
                    });
                })
                .then(() => {
                    // eslint-disable-next-line no-use-before-define
                    fetchTickets();
                })
                .catch(() => {
                    toast({
                        component: ToastificationContent,
                        props: {
                            title: "Ticket Update Failed",
                            icon: "AlertTriangleIcon",
                            variant: "danger",
                        },
                    });
                })
        };

        const perfectScrollbarSettings = {
            maxScrollbarLength: 150,
        };

        const isTicketHandlerSidebarActive = ref(false);

        // const ticketTags = [
        //     { title: "Team", color: "primary", route: { name: "apps-todo-tag", params: { tag: "team" } } },
        //     { title: "Low", color: "success", route: { name: "apps-todo-tag", params: { tag: "low" } } },
        //     { title: "Medium", color: "warning", route: { name: "apps-todo-tag", params: { tag: "medium" } } },
        //     { title: "High", color: "danger", route: { name: "apps-todo-tag", params: { tag: "high" } } },
        //     { title: "Update", color: "info", route: { name: "apps-todo-tag", params: { tag: "update" } } },
        // ];

        const ticketTags = ref([]);

        axios
            .get("/supportTicket")
            .then(res => {
                ticketTags.value = res.data.supportTicketStatuses;
                localStorage.setItem("ticketTags", JSON.stringify(res.data.supportTicketStatuses));
            })

        const resolveTagVariant = tag => {
            if (tag === "team") return "primary";
            if (tag === "low") return "success";
            if (tag === "medium") return "warning";
            if (tag === "high") return "danger";
            if (tag === "update") return "info";
            return "primary";
        };

        // Search Query
        const searchQuery = ref(routeQuery.value);
        watch(routeQuery, val => {
            searchQuery.value = val;
        });
        // eslint-disable-next-line no-use-before-define
        watch([searchQuery, sortBy], () => fetchTickets());
        const updateRouteQuery = val => {
            const currentRouteQuery = JSON.parse(JSON.stringify(route.value.query));

            if (val) currentRouteQuery.q = val;
            else delete currentRouteQuery.q;

            router.reace({name: route.name, query: currentRouteQuery});
        };

        const fetchTickets = () => {
          ticketsLoading.value = true;
            store
                .dispatch("ecommerce-tickets/fetchTickets", {
                  q: searchQuery.value,
                  filter: router.currentRoute.params.filter,
                  tag: router.currentRoute.params.tag,
                  sortBy: sortBy.value,
                })
                .then((res) => {
                    tickets.value = res.supportTickets;
                })
              .then( ()=> {
                  ticketsLoading.value=false;
              })
        };

        fetchTickets();

        const handleTicketClick = ticketData => {
            ticket.value = ticketData;
            isTicketHandlerSidebarActive.value = true;
        };

        // Single ticket isCompleted update
        const updateTicketIsCompleted = ticketData => {
            // eslint-disable-next-line no-param-reassign
            ticketData.isCompleted = !ticketData.isCompleted;
            updateTicket(ticketData);
        };

        const {mqShallShowLeftSidebar} = useResponsiveAppLeftSidebarVisibility();

        return {
            ticket,
            tickets,
            removeTicket,
            addTicket,
            updateTicket,
            clearTicketData,
            ticketTags,
          ticketsLoading,
            searchQuery,
            fetchTickets,
            perfectScrollbarSettings,
            updateRouteQuery,
            resetSortAndNavigate,

            // UI
            resolveTagVariant,
            isTicketHandlerSidebarActive,

            // Click Handler
            handleTicketClick,

            // Filters
            // formatDate,
            avatarText,

            // Single ticket isCompleted update
            updateTicketIsCompleted,

            // Left Sidebar Responsive
            mqShallShowLeftSidebar,
        };
    },
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-todo.scss";
</style>
